import noticePic from "../assets/images/notice_pic.png";
import logo from "../assets/images/logo.png";
import "./maintain.scss";
const MaintainPage = () => {
  return (
    <div className="maintaining_con">
      <div className="maintain_page">
        <div className="maintain_fl">
          <img src={noticePic} alt="" />
        </div>
        <div className="maintain_fr">
          <h6>Updating…</h6>
          <p>
            We are maintaining and updating the website, the whole process will
            take some days.
          </p>
          <div className="maintain_logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MaintainPage;
