import React, { Component, Fragment } from "react";
import "./App.css";
import MaintainPage from "./components/maintain";

class App extends Component {
  state = {
    counters: [
      { id: 1, value: 4 },
      { id: 2, value: 0 },
      { id: 3, value: 0 },
      { id: 4, value: 0 },
    ],
  };

  // 阶段一
  // 构造函数只会在初始化的时候调用一次
  // 适合初始化state或其他内容
  // 如果要使用到props，需要在构造函数中加上props这个参数
  // 并且要传给super
  constructor(props) {
    super(props);
    console.log("App - constructor ", props);
  }

  // 阶段三
  // 在组件被加入到DOM之后调用
  // 适合处理网络请求，获取服务端的数据
  componentDidMount() {
    console.log("App - componentDidMount");
  }

  handleDelete = (counterId) => {
    this.setState({
      counters: this.state.counters.filter((c) => c.id !== counterId),
    });
  };

  handleIncrement = (counter) => {
    const counters = [...this.state.counters];
    const index = counters.indexOf(counter);
    // counters[index] = [...counter];
    counters[index].value++;
    this.setState({ counters });
  };

  handleDecrement = (counter) => {
    const counters = [...this.state.counters];
    const index = counters.indexOf(counter);
    // counters[index] = [...counter];
    counters[index].value === 0
      ? (counters[index].value = 0)
      : counters[index].value--;
    this.setState({ counters });
  };

  handleReset = () => {
    this.setState({
      counters: [
        { id: 1, value: 4 },
        { id: 2, value: 0 },
        { id: 3, value: 0 },
        { id: 4, value: 0 },
      ],
    });
  };

  // 阶段二
  // render方法会在constructor后调用
  render() {
    console.log("App - render");
    return (
      <Fragment>
        <MaintainPage></MaintainPage>
      </Fragment>
    );
  }
}

export default App;
